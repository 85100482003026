
import { AxiosError } from "axios";
import { useRoute } from "vue-router";
import { ref } from "@vue/reactivity";
import { useToast } from "vue-toastification";

import projectService from "@/services/project.service";

export default {
  props: {
    participantId: {
      required: true,
      type: Number,
    },
  },
  setup(props: any) {
    const route = useRoute();
    const toast = useToast();

    const isShown = ref<boolean>(false);
    const show = () => (isShown.value = true);
    const close = () => (isShown.value = false);
    const resendEmailParticipant = async () => {
      try {
        const projectId = Number(route.params.projectId);
        const participantId = props.participantId;

        await projectService.resendEmailProjectParticipant(
          projectId,
          participantId
        );
        toast.success("Berhasil mengirim ulang email");
        close();
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      }
    };

    return {
      resendEmailParticipant,
      show,
      close,
      isShown,
    };
  },
};
