
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  props: {
    projectId: {
      type: Number,
    },
  },
  setup(props: any) {
    const router = useRouter();
    const isShown = ref<boolean>(false);

    const show = () => {
      isShown.value = true;
    };
    const close = () => (isShown.value = false);
    const toParticipantPage = () => {
      close();

      router.push(`/admin/project/${props.projectId}/invite`);
    };

    return {
      show,
      close,
      isShown,
      toParticipantPage,
    };
  },
};
