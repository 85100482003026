<template>
  <div
    v-if="show"
    class="
      origin-top-right
      absolute
      left-0
      mt-2
      w-56
      rounded-md
      shadow-lg
      bg-white
      ring-1 ring-black ring-opacity-5
      focus:outline-none
      z-20
    "
  >
    <div class="py-1" role="none">
      <a
        v-for="item in items"
        :key="item"
        @click="selectItem(item)"
        href="#"
        class="text-gray-700 block px-4 py-2 text-sm"
        >{{ item.label }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    items: Array,
  },
  setup(_, { emit }) {
    const selectItem = (val) => {
      emit("selectItem", val);
    };

    return {
      selectItem,
    };
  },
};
</script>
